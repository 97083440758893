<template>
  <div class="login">
    <v-row no-gutters>
      <v-col cols="12" md="7" class="login-bg mobile-hidden">

        <div class="loginText">

          <div class="text-center">
            <img src="https://ik.imagekit.io/soocel/wp/soocel-spotlight_XqMAWdz-X.png" />
          </div>

          <h1 class="mt-3">Welcome to Soocel</h1>
          <p>At Soocel, we understand the power of influencer marketing and how it can help businesses reach new audiences and grow their brand. With our help, you can tap into the influence and reach of top social media influencers to drive engagement and boost sales for your business. </p>

          <p class="mt-6 mb-2"><strong>Our USP</strong></p>

          <ul>
            <li>More than 50K Influencers across social media platforms</li>
            <li>Low CPI for campaigns</li>
          </ul>

          <div class="text-center mt-8 desktop-hidden">
            <v-btn depressed block color="white" @click="showInfo = false">Continue</v-btn>
          </div>

        </div>

        </v-col>
      <v-col cols="12" md="5">
        <div class="text-center">
          <a
            href="https://www.soocel.com"
            class="d-flex justify-center align-center"
          >
            <img
              src="@/assets/images/soocel-logo.png"
              height="45"
              class="logo"
            />
          </a>
        </div>

        <h2 class="text-center">Forgot Password</h2>

        <v-form
          class="login-form"
          v-model="valid"
          lazy-validation
          ref="forgetPass"
          v-on:submit.prevent="onSubmit"
        >
          <v-alert
            outlined
            :type="type"
            v-if="alert"
          >{{ alertMsg }}</v-alert>

          <label class="label">Email</label>
          <v-text-field
            v-model="userData.email"
            :rules="forgetPassRules.email"
            placeholder="Enter Email Address"
            solo
          ></v-text-field>

          <v-btn
            depressed
            dark
            large
            block
            color="primary"
            style="width: 252px;"
            :disabled="loading"
            type="submit"
            :loading="loading"
            @click="sendMail()"
          >Submit</v-btn>

          <p class="d-flex justify-space-between mt-4">
            <router-link to="/user/login">Login</router-link>
            <router-link to="/user/register">Register</router-link>
          </p>
        </v-form>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import { forgotPassword } from '@/api/user'

export default {
  data () {
    return {
      showInfo: true,
      loading: false,
      valid: true,
      userData: {},
      user: {},
      alertMsg: '',
      alert: false,
      type: 'error',
      forgetPassRules: {
        email: [
          v => !!v || 'E-mail is required',
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid E-mail'
        ]
      }
    }
  },
  methods: {
    async sendMail () {
      if (this.$refs.forgetPass.validate()) {
        try {
          this.loading = true
          const data = await forgotPassword(this.userData)
          if (data.success) {
            this.type = 'success'
            this.alertMsg = data.message
          } else {
            this.type = 'error'
            this.alertMsg = data.message
          }
          this.alert = true
        } catch (error) {
          this.type = 'error'
          this.alertMsg = 'Something went wrong'
          this.alert = true
        }
      } else {
        console.log('error submit!!')
        return false
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
</style>
